import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 className='text-uppercase text-black'>Play Now</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="row gh-5">

                <div className="col-lg-6 text-black"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <h2 className='text-uppercase text-center text-black'>Please Show Your Support?</h2>
                    <div className="space-border"></div>
                    {/* section 1 */}
                    <p>
                        Let me know if you like my music by hitting the Spotify follow button and/or by adding one of these songs to your own playlist.
                    </p>
                    <p><strong>Much love & gratitude! xx</strong></p>
                </div>

                <div className="col-lg-6"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <iframe style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/artist/47yKUvIZHKHHBOjeuFNY6R?utm_source=generator&theme=0" className="w-100" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </div>
                <div className="spacer-double"></div>
            </div>
        </div>
    );
}

export default hero;