import React from 'react';

const footer = () => {
    return (
        <footer className='bg-black'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://www.envato.com" target="_blank" rel="noreferrer">
                            <span className="copy">&copy; Copyright 2022 - Μελέτης Ιωαννίδης</span>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <div className="social-icons">
                            <a target="_blank" href="https://www.facebook.com/MeletiosMusic" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                            <a target="_blank" href="https://youtube.com/@MELELETIOS" rel="noreferrer"><i className="fa fa-youtube"></i></a>
                            <a target="_blank" href="https://open.spotify.com/artist/47yKUvIZHKHHBOjeuFNY6R?si=7nTAnXVvSeeuH8zBkkzAXA&utm_source=facebook&utm_medium=fb_messenger&nd=1" rel="noreferrer"><i className="fa fa-spotify"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default footer;