import React, { useEffect, useRef } from "react";
import { Parallax, Background } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from "../layout/Navbar";
import Hero from "../section/hero";
import About from "../section/about";
import Blockquote from "../section/blockquote";
import Whatido from "../section/whatido";
import Gallery from "../section/gallery";
import Resume from "../section/resume";
import Counter from "../section/counter";
import Blog from "../section/blog";
import Contact from "../section/contact";
import Footer from "../section/footer";
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from "../layout/ScrollToTop";
import { GlobalStyles } from "./style/video";
import AboutUs from "../section/about-us";

export default function Home() {
  const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Preloader />
      <div className="home">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* HERO */}
        <section id="hero-area" className="bg-bottom py-0">
          <Parallax strength={300}>
            <Background className="custom-video">
              <video
                playsInline
                loop
                muted
                alt="All the devices"
                src="./video/hero.mp4"
                ref={videoEl}
              />
            </Background>
            <Hero />
            <Link smooth spy to="about">
              <span className="mouse transition" id="fly">
                <span className="scroll"></span>
              </span>
            </Link>
          </Parallax>
        </section>

        {/* ABOUT */}
        <section id="about" className="pb-0">
          <About />
          <Blockquote />
        </section>

        {/* What I DO */}
        {/* <section id="whatido">
          <Whatido />
        </section> */}

        {/* portfolio */}
        <section id="gallery">
          <Gallery />
        </section>

        {/* Play now */}
        <section id="resume" className="pb-0 bg-white">
          <Resume />
        </section>

        {/* Gallery */}
        <section id="blog" className="pb-0">
          <Blog />
        </section>

        {/* contact */}
        <section id="contact" className="pb-0">
          <Contact />
        </section>

        <section id="aboutMe" className="pb-0 bg-white">
          <AboutUs />
          <Footer />
        </section>

        <div className="float-text">
          <div className="de_social-icons">
            <span className="buton">
              <a
                className="color"
                href="https://www.facebook.com/MeletiosMusic"
                target="_blank"
              >
                <i className="fa fa-facebook fa-lg"></i>
              </a>
            </span>
            <span className="buton">
              <a
                className="color"
                href="https://youtube.com/@MELELETIOS"
                target="_blank"
              >
                <i className="fa fa-youtube fa-lg"></i>
              </a>
            </span>
            <span className="buton">
              <a
                className="color"
                href="https://open.spotify.com/artist/47yKUvIZHKHHBOjeuFNY6R?si=7nTAnXVvSeeuH8zBkkzAXA&utm_source=facebook&utm_medium=fb_messenger&nd=1"
                target="_blank"
              >
                <i className="fa fa-spotify fa-lg"></i>
              </a>
            </span>
          </div>
          <span style={{color:'#D2AE6D'}}>Follow Me</span>
        </div>
      </div>
      <ScrollToTopBtn />
    </>
  );
}
