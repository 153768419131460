import React from 'react';
import AOS from 'aos';
AOS.init();



const AboutUs = () => {
    return (
        <div className="container">

            <div className="row gh-5">

                <div className="col-lg-6 text-black"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <h2 className='text-uppercase text-black text-center'>About Me</h2>
                    <div className="space-border"></div>
                    <p>
                    Hello my fellow readers, Welcome to my site. I hope you enjoy my own little journey in the world of music production and composition. My name is Meletios Ioannidis and I’m based in Greece. As most of the people, I started my music career in a very young age, and not necessarily in the classical world. I started learning and playing piano and arranging keyboard. I passed in the musical school of my town and I studied music for 6 years. As a student in the musical school I had to pick two instruments to learn throughout those 6 years so I chose the piano and of course, as almost every 12 years old boy, the guitar! Eventually I led myself in the next years learning and creating music for local projects in the beginning until I finally reaching to the point I am now. My skills in composition and orchestration are self-taught and my inspiration can be found in all the music streaming services and online music stores.
                    </p>
                </div>

                <div className="col-lg-6"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <img className='img-fluid' src="./img/meletios_black.png" />
                </div>
                <div className="spacer-double"></div>
            </div>
        </div>
    );
}

export default AboutUs;