import React from 'react';
import Typed from "react-typed";
import AOS from 'aos';

AOS.init({
    offset: 200,
    duration: 800,
    easing: 'ease-in-out-sine',
    delay: 200,
    mirror: true
});

const hero = () => {
    return (
        <div className="v-center position-relative" style={{ zIndex: 5 }}>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h6 className="color"
                            data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                        ><span className='bg-white py-1 px-2 rounded text-black me-3'>New Release</span> 11/09/2022
                        </h6>
                        <div className="spacer-20"></div>
                        <div className="h1_big"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                        >
                            <span>THE CALL OF DESTINY</span>
                        </div>
                        <h6>- Μελέτης Ιωαννίδης</h6>
                        <a type='submit' id='send_message' href="mailto:contact@meletiosmusic.com" className="nav-link btn-main mt-4 radius">
                            Contact Me
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default hero;